.NavigationMenu {
    nav {
        margin-top: 20px;
        margin-bottom: 30px;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        width: 100%;
        display: block;
        padding: 5px;
    }

    a {
        color: #8ae6ff;
        text-align: center;
        text-decoration: none;
    }

}