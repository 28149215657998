@import-normalize;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(11px + 2vmin);
  color: rgb(220, 220, 220);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: calc(18px + 2vmin);
  font-weight: 500;
  color: #ffffff;
  padding: 10px;
}

h2 {
  padding-top: 10px;
  font-size: calc(11px + 2vmin);
  font-weight: 500;
  color: #ffffff;
}

.Background > h2 {
  color: #8ae6ff;
}

h3 {
  padding-top: 10px;
  font-size: calc(11px + 2vmin);
  font-weight: 400;
}

.mainList {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
    padding-bottom: 10px;
  }
}


.innerList {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
    padding-bottom: 10px;
  }
}

input[type=button] {
  padding: 13px 25px;
  font-size: calc(4px + 2vmin);
  background-color: #347488;
  color: #ffffff;
  border-radius: 3px;
}


/* CSS */
input[type=button] {
  align-items: center;
  background-clip: padding-box;
  background-color: #347488;
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}
